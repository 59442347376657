@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutSuccess {
    &-NoticeText {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }

    &-Heading {
        font-size: 36px;
        font-weight: 500;
        line-height: 47px;
        margin-top: 56px;
    }

    &-NoticeId {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin: 16px 0;
    }

    &-Emailtext {
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        color: $dark-transparent;
    }


    &-PaymentRedirectButton {
        margin-top: 32px;
        
        a.Button {
            width: 277px;
            height: 56px;
        }
    }
}

@include mobile {
    .CheckoutSuccess {
        &-Products-InfoMobile {
            p {
                font-weight: 500;
                font-size: 2.5rem;
                border-bottom: 1px solid $color-neutral-30;
                padding-bottom: 3rem;
            }
        }

        &-Products-Info {
            display: none;
        }
    }
}

@include tabletAndAbove {
    .CheckoutSuccess {
        &-Products-InfoMobile {
            display: none;
        }

        >h3 {
            font-size: 2.5rem;
        }
    }
}

@include desktopAndAbove {
    .CheckoutSuccess {
        &-Products-Info {
            grid-template-columns: 1fr 115px 115px 115px;
        }
    }
}
