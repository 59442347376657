@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutOrderSummary {
    padding: 24px;
    border: 1px solid $color-neutral-35;

    &-ShippingTime {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;

        p {
            font-size: 16px;
            margin-bottom: 0;
        }
    }

    .PriceToFreeDelivery {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 25px;
    }

    &-Header {
        padding-bottom: 24px;
        border-bottom: 1px solid $color-neutral-35;

        h2 {
            font-size: 24px;
            line-height: 32px;
            position: relative;
        }
    }

    &-SummaryItem_isTotal {
        margin-top: 40px;
        padding-bottom: 4px;
        justify-content: flex-end;
        gap: 5px;
        border-top: 0;
        padding-top: 0;
    }

    &-SummaryItem_isTotal p, &-SummaryItem_isTotal span {
        font-size: 24px;
        line-height: 135%;
        font-weight: 500;
    }

    &-Text {
        font-size: 16px;
        max-width: 70%;
        line-height: 20px;

        span {
            display: none;
        }
    }

    &-TaxSubtitle {
        color: $color-neutral-50;
        text-align: right;
        margin-bottom: 32px;
    }

    &-AppendedContent {
        margin-top: 10px;
    }

    .ExpandableContent {
        margin-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $color-neutral-35;

        &-Content {
            margin-top: 0;
        }

        &-Button {
            justify-content: space-between;
            margin-bottom: 0;

            &_isContentExpanded {
                margin-bottom: 16px;
            }
        }

        button:not(.RewardPoints-Button) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 48px;
            padding: 14px 56px 13px;
            background-color: $color-neutral-20;
            color: $black;
            border: 0;
        }

        input {
            background-color: $white;
            border: 1px solid $color-neutral-35;
            width: 100%;
        }

        &-Heading {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
        }
    }

    &-Discount {
        display: flex;
        flex-direction: column;

        form.Form {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &-Input {
            margin-right: 0;

            input {
                border: 1px solid $color-neutral-35;
                background-color: $white;
                width: 100%;
            }
        }

        .Button {
            width: 100%;
            margin-left: 0;
        }

        button.Button {
            background-color: $color-neutral-20;
            color: $black;
            border: 0;
        }
    }

    .RewardPoints {
        &-Balance {
            font-size: 14px;
            font-weight: 400;
            color: $dark-transparent;
            margin-bottom: 16px;
            margin-top: 16px;
        }

        &-BalanceBolded {
            color: $black;
            font-weight: 500;
        }

        &-Notification {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 40px;
            padding: 10px;
            margin-bottom: 16px;
            font-size: 15px;
            color: $white;
            background-color: $color-other-4;
        }

        &-Checkbox {
            margin-top: 22px;

            .Field.Field_type_checkbox {
                margin-bottom: 0;

                .input-control {
                    flex: 0 0 24px;
                    height: 24px;
                    max-width: 24px;
                    margin-right: 16px;
                    border: 1px solid $color-neutral-30;
                }
            }
        }

        &-CheckboxLabel {
            font-size: 14px;
            font-weight: 400;
            color: $color-neutral-70;
        }

        &-CheckboxLabelBolded {
            display: inline-block;
            font-weight: 500;
            color: $black;
        }

        &-JoinLoyaltyProgram {
            font-size: 14px;
            color: $black;
            padding-top: 24px;
        }

        &-Buttons {
            display: flex;
            gap: 16px;
            margin-top: 16px;

            .RewardPoints-Button {
                padding: 0;
                width: 141px;
            }
        }

        .Field {
            input {
                font-size: 16px;
                padding: 13px 80px 14px 16px;

                &::placeholder {
                    color: $color-neutral-50;
                }
            }
        }
    }

    &-Coupon {
        margin-bottom: 15px;
    }


    .CartCoupon {
        margin-bottom: 0;

        &-Input {
            margin-right: 0;
        }

        form.Form {
            margin-top: 24px;
        }
    }

    &-BackToCart {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 24px;

        a {
            color: $color-neutral-90;
        }

        svg path {
            fill: $color-primary-50;
        }
    }

    &_isCheckout {
        box-shadow: 0 0 20px 0 rgba(69, 6, 46, 0.10), 0 30px 75px 0 rgba(69, 6, 46, 0.15);
        padding: 32px;
        border: 0;

        .CheckoutOrderSummary {
            &-OrderItems {
                margin-bottom: 12px;
            }

            &-ShippingTime {
                p {
                    font-size: 14px;
                }
            }

            &-SummaryItem {
                padding: 10px 0;

                &_isTotal {
                    justify-content: space-between;
                    margin-top: 0;
                    padding-top: 0;
                    padding-bottom: 16px;

                    p, span {
                        font-size: 24px;
                    }

                    span {
                        color: $color-other-8;
                    }
                }
            }

            &-Text {
                font-size: 14px;
            }

            &-TaxSubtitle {
                display: none;
            }

            &-CartItemList {
                border-top: 1px solid $color-neutral-35;

                &_isScrollable {
                    max-height: 315px;
                    overflow-y: scroll;
                    padding-right: 16px;

                    @include scrollbar($color-primary-95);
                }

                .CartItem {
                    border: 0;
                }
            }

            &-ReviewPoints {
                margin-top: 12px;
                border-top: 1px solid $color-neutral-35;
            }
        }

        .ExpandableContent {
            margin-top: 17px;
            padding-bottom: 17px;

            &-Heading {
                line-height: 20px;
                font-size: 15px;
            }
        }
    }
}

@include mobile {
    .CheckoutOrderSummary {
        &_isCheckout {
            box-shadow: none;
            padding: 0;
            margin-top: 48px;
            margin-bottom: 48px;

            .CheckoutOrderSummary {
                &-OrderItems {
                    background: none;
                }
            }
        }
    }
}

@include mobileAndTablet {
    .CheckoutOrderSummary {
        &-ButtonWrapper {
            position: relative;
            height: fit-content;
            border-top: 0;
            padding: 0;
        }

        &-Coupon {
            padding: 0;
            margin-left: 0;
            margin-right: 0;
            border: 0;
        }

        &-SummaryItem_isTotal p, &-SummaryItem_isTotal span {
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0px;
        }

        .ExpandableContent {
            border-top: 0;

            &-Button {
                padding: 0;
            }
        }

        &-Header {
            display: block;
        }
    }
}

@include tabletAndAbove {
    .CheckoutOrderSummary {
        margin-top: 24px;

        &_isCheckout {
            margin-top: 106px;
        }
    }
}

@include desktopAndAbove {
    .CheckoutOrderSummary {
        position: sticky;
        top: calc(var(--header-nav-height) + 52px);
        margin-top: 0;

        &_isCheckout {
            position: relative;
            margin-top: 106px;
            top: 0;
        }
    }
}
