@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutAddress {
    &Book {
        margin-bottom: 16px;

        &-Heading {
            background: transparent;
            font-size: 30px;
            line-height: 42px;
            font-weight: 500;
        }

        &-Button {
            color: $color-primary-95;
            background: transparent;
        }

        &-Wrapper {
            display: block;
        }

        &-ExpandableContentList {
            > .ExpandableContent-Button {
                justify-content: end;
                padding-right: 10px;
            }

            .ExpandableContent-Heading {
                font-weight: 400;
            }
        }
    }

    .CheckoutAddressTable-Button .KeyValueTable-Heading {
        font-size: 16px;
    }
}
