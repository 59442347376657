@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Checkout {
    &-ShippingTime {
        margin: 30px 0;
        width: 100%;

        th {
            font-size: 16px;
        }

        tbody {
            tr {
                td {
                    font-size: 14px;
                }
            }
        }
    }

    &-ButtonWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 64px;

        @include mobile {
            gap: 10px;
            flex-direction: column;
            align-content: center;
            align-items: center;
        }

        a {
            align-items: center;
            cursor: pointer;
            font-size: 15px;
            color: $color-neutral-70;
            justify-content: center;
            height: var(--button-height);
            line-height: calc(var(--button-height) - 2 * var(--button-padding)) ;
            padding-inline: var(--button-padding);
            text-decoration: var(--button-text-decoration);
            transition-duration: .25s;
            transition-timing-function: ease-out;
            transition-property: background-color, color, border;
            will-change: background-color, color, border;
            text-align: center;
            letter-spacing: 0.5px;
            display: inline-flex;
            vertical-align: top;
            white-space: nowrap;
            width: 277px;
            border: 1px solid $color-neutral-30;

            &:hover,
            &:focus {
                text-decoration: var(--button-hover-text-decoration);

                @include desktop {
                    background-color: $color-neutral-30;
                }
            }

            svg {
                transform: rotate(180deg);

                path {
                    fill: $color-neutral-70;
                }
            }
        }

        button {
            width: 277px;
        }
    }
}
