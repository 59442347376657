@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutDeliveryOption {
    border: 1px solid $color-neutral-35;
    padding: 13px 24px;
    margin-bottom: -1px;

    &_isActive {
        background: $gray-transparent;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: -1px;
            left: -1px;
            border: 1px solid $color-other-8;
            z-index: 1;
            pointer-events: none;
        }
    }

    &-LargeSizeDelivery {
        padding-top: 16px;
        color: $color-other-8;
        display: flex;
        gap: 10px;
        font-size: 13px;

        svg {
            flex: 1 0 auto;
            margin-top: 3px;
        }
    }

    &_isDisabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &-DeliveryMethodIconWrapper {
        width: 62px;
        padding-left: 8px;
    }

    &-DeliveryMethodIcon {
        max-width: 30px;
        display: block;

        &.dpd {
            max-width: 34px;
        }
    }

    &-Button {
        flex-wrap: wrap;

        &_isDisabled {
            cursor: not-allowed;

            .CheckoutDeliveryOption-Row > span {
                color: $color-neutral-75
            }
        }
    }

    &-Row {
        position: initial;
        width: 80%;
        font-size: 13px;
        color: $color-other-10;
    }

    &-Price {
        font-weight: 500;
        padding-left: 10px;
    }

    &-Title {
        text-align: left;
        line-height: 20px;
    }
}
