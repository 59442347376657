@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutPayment {
    border: 1px solid $color-neutral-35;
    padding: 13px 24px;
    margin-bottom: -1px;

    &::before {
        display: none;
    }

    &_isActive {
        background: $gray-transparent;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: -1px;
            left: -1px;
            border: 1px solid $color-other-8;
            z-index: 1;
            pointer-events: none;
        }
    }

    &-Button {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
    }

    &-IconWithName {
        display: flex;
        align-items: center;
    }

    &-PaymentMethodIconWrapper {
        width: 62px;
        padding-left: 8px;
    }

    &-PaymentMethodIcon {
        max-width: 25px;
        display: block;

        &.przelewy24, &.przelewy24_266, &.przelewy24_227 {
            max-width: 40px;
        }

        &.przelewy24_154 {
            max-width: 30px;
        }
    }

    &-Row {
        position: initial;
        width: 80%;
        font-size: 13px;
        color: $color-other-10;
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        font-weight: 400;
        line-height: 28px;
    }
}
