@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutAddressForm {
    .FieldForm-Fields {
        > .Field-Wrapper {
            width: 100%;
        }

        > .FieldGroup-Wrapper_street {
            width: 100%;

            .FieldGroup {
                display: flex;
                justify-content: space-between;

                .Field-Wrapper {
                    width: 48%;
                }
            }
        }

        > .Field-Wrapper_type_select {
            pointer-events: none;
            opacity: 0.7;

            .FieldSelect-Arrow {
                display: none;
            }
        }
    }
}
