@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutBilling {
    &-Comment {
        margin: 40px 0 0;

        textarea {
            border: 1px solid $color-neutral-30;
            width: 100%;
            padding: 30px;
            border-radius: 0;
        }
    }

    &-Heading {
        font-size: 30px;
        font-weight: 500;
        margin: 0 0 30px;
    }

    &-TACItem {
        display: flex;
        margin-bottom: 20px;
    }

    &-TermsAndConditions {
        margin-top: 30px;
    }

    .Field-Wrapper.Field-Wrapper_type_checkbox {
        display: inline;
    }

    &-RegisterTerm, &-CheckoutTerms {
        label.Field-CheckboxLabel {
            margin: 0;
        }

        label {
            margin-bottom: 10px;
        }

        .Field-Label_isRequired {
            color: $color-secondary-70;
            font-size: 16px;
            margin-right: 5px;
            padding: 0;
        }

        .Field-ErrorMessages {
            width: 250px;
        }

        p , .Field-CheckboxLabel {
            color: $color-neutral-70;
            font-size: 16px;
            display: inline;
            margin: 0;

            a {
                color: $color-primary-50;

                &:hover {
                    color: $color-primary-60;
                }
            }
        }
    }

    &-TACLabel,
    &-CeneoOpineo,
    &-Newsletter {
        > * {
            display: inline;
            color: $color-neutral-70;
            font-size: 16px;

            a {
                color: $color-neutral-70;
            }
        }

        &_required {
            &::before {
                content: "*";
                color: $color-secondary-70;
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }

    &-CeneoOpineo,
    &-Newsletter {
        display: flex;

        label {
            margin-top: 5px;
        }
    }

    &-CeneoOpineo {
        margin-bottom: 10px;
    }
}
