@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutDpdPickup {
    width: 100%;
    margin: 0 0 8px;
    text-align: left;

    &-SelectedParcelLocker {
        &Address {
            display: flex;
            flex-direction: column;
            margin: 16px 0 0;

            > span {
                font-size: 12px;

                &:first-of-type {
                    font-weight: 600;
                }
            }
        }
    }

    &-DpdPickupButton {
        background: $color-other-8;
        border: none;

        &.Button {
            margin: 24px 0 0 0;
            height: 41px;
            font-size: 12px;
            width: 100%;
        }
    }

    &-SelectedParcelLockerTitle {
        font-size: 12px;
        font-weight: 400;
        color: $black;
        margin-top: 16px;
        display: block;
    }

    iframe {
        display: none;
        pointer-events: none;
    }
}

[dir='ltr'] {
    .DpdPickupMapPopup {
        .Popup-Heading {
            @include mobileAndTablet {
                font-size: 16px
            }
        }

        .Popup-Header {
            border: none;
            background: #a8959a;
            padding: 14px;

            @include desktopAndAbove {
                padding: 34px 30px 34px 66px;
                height: 96px;
                display: flex;
                align-items: center;
                width: 100%;
                z-index: 401;
            }

            h3 {
                margin: 0;
                color: $white;
                font-weight: 600;
            }
        }

        .CheckoutDpdPickup {
            &-MapWrapper {
                height: calc(100% - 60px);

                @include tabletAndAbove {
                    height: calc(100% - 55px);
                }

                @include desktopAndAbove {
                    height: calc(100% - 96px);
                }
            }

            &-Map {
                height: 100%;

                iframe {
                    height: 100%;
                    display: block;
                    width: 100%;
                }
            }
        }

        .Popup-Content {
            width: 1080px;
            height: 100%;
            max-width: 100vw;
            max-height: 100vh;
            padding: 0;
            overflow: hidden;

            @include desktopAndAbove {
                height: 617px;
            }
        }

        .Popup-CloseBtn {
            right: 33px;
            top: 0;
            height: 100%;

            @include mobileAndTablet {
                right: 10px;
                top: 2px;
            }

            svg {
                fill: $white;
            }
        }
    }
}

