@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutAddressTable {
    margin-bottom: 10px;

    &-Button {
        &_isSelected {
            --check-mark-opacity: 0;
            --checkout-address-table-border: var(--checkout-address-table-check-mark-selected-color);
            --check-mark-color: var(--checkout-address-table-check-mark-selected-color);

            border-color: $color-other-8;

            .KeyValueTable-Heading {
                background: $color-neutral-10;
            }

            .Field [type='radio']:checked + .input-control {
                border-color: $color-primary-95;
            }
        }

        .Field {
            margin-bottom: 0 !important;

            [type='radio'] {
                + .input-control {
                    border-color: $color-primary-95;
                }

                &:checked + .input-control {
                    border-color: $color-other-8;
                }
            }
        }

        .KeyValueTable {
            color: $color-neutral-90;

            .Field-Wrapper_type_radio {
                margin: 0 16px 0 0;
            }

            &-Heading {
                font-size: 13px;
                padding: 14px;
            }

            tbody tr th,
            tbody tr td {
                font-size: 12px;
                padding: 10px;
            }

            tbody tr th {
                font-weight: 400;
            }

            tbody tr td {
                overflow-wrap: break-word;
                font-weight: 500;
            }
        }
    }
}
