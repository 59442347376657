@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutInpost {
    width: 100%;
    margin: 0 0 8px;
    text-align: left;

    &-SelectedParcelLocker {
        &Address {
            display: flex;
            flex-direction: column;
            margin: 16px 0 0;

            > span {
                font-size: 12px;

                &:first-of-type {
                    font-weight: 600;
                }
            }
        }
    }

    &-SelectedParcelLockerTitle {
        font-size: 12px;
        font-weight: 400;
        color: $black;
        margin-top: 16px;
        display: block;
    }

    &-InpostButton {
        background: $color-other-8;
        border: none;

        &.Button {
            margin: 24px 0 0 0;
            height: 41px;
            font-size: 12px;
            width: 100%;
        }
    }
}

[dir='ltr'] {
    .InpostMapPopup {
        .StoreInPickUpPopup-Heading {
            position: absolute;
            color: blue;
            font-size: 22px;
            left: 25px;
            top: 25px;
        }

        .Popup-Heading {
            @include mobileAndTablet {
                font-size: 16px
            }
        }

        .Popup-Header {
            border: none;
            background: $color-primary-95;
            padding: 14px;

            @include desktopAndAbove {
                padding: 34px 30px 34px 66px;
                height: 96px;
                display: flex;
                align-items: center;
                position: absolute;
                width: 100%;
                z-index: 401;
            }

            h3 {
                margin: 0;
                color: $white;
                font-weight: 600;
            }
        }

        .NotificationList {
            left: 0;
            background: green;
        }

        .Popup-Content {
            width: 1080px;
            height: 100vh;
            max-width: 100vw;
            max-height: 100vh;
            padding: 0;
            overflow: hidden;

            @include desktopAndAbove {
                height: 617px;
            }
        }

        .Popup-CloseBtn {
            right: 33px;
            top: 0;
            height: 100%;

            @include mobileAndTablet {
                right: 10px;
                top: 2px;
            }

            svg {
                fill: $white;
            }
        }

        .easypack-widget {
            position: absolute;
            bottom: 0;
            max-height: calc(100% - 60px);

            @include tabletAndAbove {
                max-height: calc(100% - 55px);
            }

            @include mobileAndTablet {
                padding: 0;
            }

            .view-chooser,
            .list-widget {
                display: none !important;
            }

            .search-widget {
                background: none;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                z-index: 401;

                @include desktopAndAbove {
                    position: absolute;
                    top: -7px;
                    width: 100%;
                    max-width: 750px;
                    left: 60%;
                    transform: translate(-50%, -50%);
                    padding: 0 69px 0 48px;
                }

                @include mobileAndTablet {
                    width: 100%;
                    justify-content: stretch;
                    background: $color-primary-final;
                }

                .search-group:not(.with-filters) {
                    position: relative;
                    padding: 0 0 10px;

                    @include desktopAndAbove {
                        padding: 22px 0;
                    }

                    @include tabletAndAbove {
                        padding: 0;
                    }

                    @include mobileAndTablet {
                        width: 100%;
                        padding: 10px;
                    }

                    .search-input {
                        border-radius: 0;
                        height: 48px;
                        padding: 0 27px;

                        @include mobile {
                            padding-right: 45px;
                        }
                    }

                    #searchLoader {
                        position: absolute;
                        right: 60px;
                        top: 1px;
                        height: 38px;
                        background-color: transparent;

                        > .ball-spin-fade-loader {
                            top: 12px;
                        }
                    }

                    .search-group-btn {
                        position: absolute;
                        right: 30px;

                        @include mobile {
                            right: 45px;
                        }

                        .btn-search {
                            background-image: url('../../assets/images/search.png') !important;
                            background-color: transparent !important;
                            border: none;
                            height: 96%;
                            min-height: unset;
                            width: 20px;
                        }
                    }
                }

                .inpost-search__list {
                    left: 48px;
                    right: 0;
                    width: 70%;
                    top: 48px;

                    @include mobile {
                        width: 100%;
                        left: 0;
                    }
                }
            }

            .leaflet-popup,
            .info-box-wrapper {
                .leaflet-popup-content-wrapper,
                .info-window .content {
                    border: 1px solid $color-secondary-75;
                    background: $white !important;
                    padding: 24px;
                    border-radius: 0;
                    margin: 0;

                    .point-wrapper {
                        text-align: left;
                        padding: 0;

                        h1 {
                            display: inline;
                            color: #000 !important;
                            font-size: 16px;
                            font-family: $font-poppins;
                            font-weight: 400;

                            + p {
                                font-family: $font-poppins;
                                display: inline;
                                font-weight: 400;
                                margin-left: 5px;
                                font-size: 16px;
                            }
                        }
                    }

                    .links {
                        border: none;
                        padding: 0;

                        .route-link,
                        .details-link {
                            display: none !important;
                        }

                        .select-link {
                            background: $color-secondary-75;
                            border: none;
                            border-radius: 0;
                            padding: 10px;
                            margin-top: 24px;
                            max-width: 185px;
                            width: 100%;
                        }
                    }

                    .apm_doubled {
                        display: none;
                    }
                }

                .leaflet-popup-content {
                    margin: 0;
                }
            }

            .leaflet-control-container {
                display: none;
            }
        }
    }
}
