@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --checkout-step-active-count-background: #{$color-primary-85};
}

.CheckoutSteps {
    &-Step {
        &Count {
            &::after {
                @include mobile {
                    font-size: 20px;
                }
            }
        }
    }
}

@include mobile {
    .CheckoutSteps {
        padding: 0 0 16px 0;

        &-StepCount {
            margin-right: 16px;
        }

        &-Step {
            svg {
                margin: 0 16px;
            }
        }
    }
}
