@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

#checkout-shipping-ruch-init {
    #ruch_widget {
        display: block !important;

        .searchBar {
            .ruch_widget_inp_wrapper {
                .ruch_widget_inp {
                    border: 1px solid $white !important;
                    color: $orlen-color-grey;
                }
            }

            .ruch_widget_but_s {
                border: 1px solid $white !important;
                color: $white;
                font-size: 16px !important;
            }

            &__location {
                display: none !important;
            }
        }

        @include mobileAndTablet {
            .searchBar {
                height: auto;
                background: $color-primary-95;
                padding: 10px;
            }
        }

        @include tabletAndAbove {
            .searchBar {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                .ruch_widget_inp_wrapper {
                    width: 350px;
                }

                .ruch_widget_but_s {
                    z-index: 999;
                    max-width: 100px;
                    margin-left: 5px !important;
                }
            }
        }

        @include desktopAndAbove {
            .searchBar {
                height: 96px;
            }
        }

        .ruch_widget_filter {
            display: none !important;
        }

        @media (min-width: 1200px) {
            .ruch_sidebar {
                padding-top: 0;

                .mapPoint__confirm {
                    background: $color-other-8;
                    border-radius: 0;
                }

                .mapPoint {
                    border-bottom: 1px solid $color-primary-final;
                }
            }

            .leaflet-popup-content-wrapper button {
                color: $white;
            }

            .ruch_sidebar::before {
                content: none;
            }
        }

        .mapPoint_popup {
            button {
                background: $color-other-8;
                border: none;
                border-radius: 0;
            }

            b {
                color: $color-other-8;
            }
        }

        .leaflet-popup-content-wrapper {
            border-radius: 0;
        }

        .ruch_widget_inp {
            border-radius: 0 !important;
        }

        .ruch_widget_but_s {
            border-radius: 0;
        }

        .ruch_sidebar .mapPoint__price {
            display: none;
        }
    }

    .ruch_widget {
        padding: 0 !important;
    }
}

.CheckoutOrlen {
    display: flex;
    flex-direction: column;
    margin: 0 0 8px;
    text-align: left;
    width: 100%;

    &-SelectedParcelLocker {
        &Address {
            display: flex;
            flex-direction: column;
            margin: 16px 0 0;

            > span {
                font-size: 13px;

                &:first-of-type {
                    font-weight: 700;
                }
            }
        }
    }

    &-SelectedParcelLockerTitle {
        font-size: 12px;
        font-weight: 400;
        color: $black;
        margin-top: 16px;
        display: block;
    }

    &-OrlenPickupButton {
        background: $color-other-8;
        border: none;

        &.Button {
            margin: 24px 0 0 0;
            height: 41px;
            font-size: 12px;
            width: 100%;
        }
    }
}

.OrlenPickupMapPopup {
    .StoreInPickUpPopup-Heading {
        position: absolute;
        color: $orlen-color-blue;
        font-size: 22px;
        left: 25px;
        top: 25px;
    }

    .Popup-Heading {
        @include mobileAndTablet {
            font-size: 16px
        }
    }

    .Popup-Header {
        border: none;
        background: $color-primary-95;
        padding: 14px;

        @include desktopAndAbove {
            padding: 34px 30px 34px 66px;
            height: 96px;
            display: flex;
            align-items: center;
            position: absolute;
            width: 100%;
            z-index: 401;
        }

        h3 {
            margin: 0;
            color: $white;
            font-weight: 600;
        }
    }

    .NotificationList {
        left: 0;
        background: $orlen-color-green;
    }

    .Popup-Content {
        width: 1080px;
        height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        padding: 0;
        overflow: hidden;

        @include desktopAndAbove {
            height: 596px;
        }
    }

    .Popup-CloseBtn {
        inset-inline-end: 33px;
        top: 0;
        height: 100%;

        @include mobileAndTablet {
            right: 10px;
            top: 2px;
        }

        svg {
            fill: $white;
        }
    }

    .easypack-widget {
        position: absolute;
        bottom: 0;
        max-height: 85%;

        @include mobileAndTablet {
            padding: 0;
        }

        .view-chooser,
        .list-widget {
            display: none !important;
        }

        .search-widget {
            background: none;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            z-index: 401;

            @include tabletAndAbove {
                width: 55%;
                position: absolute;
                bottom: 100%;
            }

            @include desktopAndAbove {
                left: 30%;
            }

            @include tabletAndAbove {
                width: 100%;
                max-width: 750px;
                left: 60%;
                transform: translate(-50%, -50%);
                padding: 0 69px 0 48px;
            }

            @include mobile {
                width: 100%;
                justify-content: stretch;
                background: $color-secondary-75;
            }

            .search-group:not(.with-filters) {
                position: relative;
                padding: 0 0 10px;

                @include desktopAndAbove {
                    padding: 22px 0;
                }

                @include tabletAndAbove {
                    padding: 0;
                }

                @include mobile {
                    width: 100%;
                    padding: 0 15px 10px;
                }

                .search-input {
                    border-radius: 0;
                    height: 48px;
                    padding: 0 27px;

                    @include mobile {
                        padding-right: 45px;
                    }
                }

                #searchLoader {
                    position: absolute;
                    right: 60px;
                    top: 1px;
                    height: 38px;
                    background-color: transparent;

                    > .ball-spin-fade-loader {
                        top: 12px;
                    }
                }

                .search-group-btn {
                    position: absolute;
                    right: 30px;

                    @include mobile {
                        right: 45px;
                    }

                    .btn-search {
                        background-image: url('../../assets/images/search.png') !important;
                        background-color: transparent !important;
                        border: none;
                        height: 96%;
                        min-height: unset;
                        width: 20px;
                    }
                }
            }

            .inpost-search__list {
                left: 48px;
                right: 0;
                width: 70%;
                top: 48px;

                @include mobile {
                    width: 100%;
                    left: 0;
                }
            }
        }

        .leaflet-popup,
        .info-box-wrapper {
            .leaflet-popup-content-wrapper,
            .info-window .content {
                border: 1px solid $color-secondary-75;
                background: $white !important;
                padding: 24px;
                border-radius: 0;
                margin: 0;

                .point-wrapper {
                    text-align: left;
                    padding: 0;

                    h1 {
                        display: inline;
                        color: $black !important;
                        font-size: 16px;
                        font-family: $font-poppins;
                        font-weight: 400;

                        + p {
                            font-family: $font-poppins;
                            display: inline;
                            font-weight: 400;
                            margin-left: 5px;
                            font-size: 16px;
                        }
                    }
                }

                .links {
                    border: none;
                    padding: 0;

                    .route-link,
                    .details-link {
                        display: none !important;
                    }

                    .select-link {
                        background: $color-secondary-75;
                        border: none;
                        border-radius: 0;
                        padding: 10px;
                        margin-top: 24px;
                    }
                }

                .apm_doubled {
                    display: none;
                }
            }

            .leaflet-popup-content {
                margin: 0;
            }
        }

        .leaflet-control-container {
            display: none;
        }
    }
}
