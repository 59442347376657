@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutThankyouPage {
    @include page-container;

    text-align: center;
    padding-top: 80px;
    padding-bottom: 100px;

    .TypographyHeader {
        margin-bottom: 64px;
    }
}

@include mobile {
    .CheckoutThankyouPage {
        padding-bottom: 64px;

        .TypographyHeader {
            margin-bottom: 48px;
            font-size: 24px;
            line-height: 33px;
        }

        a {
            width: 100%;
        }
    }
}

@include tablet {
    .CheckoutThankyouPage {
        .TypographyHeader {
            margin-bottom: 56px;
            font-size: 30px;
            line-height: 42px;
        }
    }
}
