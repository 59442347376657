@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Checkout {
    min-height: fit-content;

    &-Heading {
        font-size: 30px;
        line-height: 42px;
        font-weight: 500;
        margin-top: 64px;
        margin-bottom: 32px;
    }

    &-SubHeading {
        font-size: 24px;
        line-height: 34px;
        font-weight: 500;
        margin-bottom: 16px;
        margin-top: 0;

        span {
            color: $color-other-8;
        }
    }

    &-Discount {
        margin-top: 24px;
    }

    &-Wrapper {
        max-width: 1440px;

        &_isDetailsStep {
            grid-template-columns: repeat(1, 1fr);

            .Checkout-Header {
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 48px;
                padding-bottom: 0;
            }

            .Checkout-Title {
                font-weight: 600;
                text-align: center;
                font-size: 30px;
            }

            .Checkout-IconWrapper {
                text-align: center;
                margin-bottom: 2rem;
                width: 100%;
            }
        }
    }

    &-CheckoutTopBanner {
        @include page-container;
    }

    &-Addresses, &-Methods {
        flex: 1;
    }

    &-Addresses {
        .Field {
            margin-bottom: 16px;

            &-Label {
                font-size: 12px;
                padding-bottom: 4px;

                &_isRequired {
                    font-weight: 400;
                }
            }

            input {
                --input-padding: 15px 21px 16px 16px;

                line-height: 17px;
            }

            &-ErrorMessages {
                margin-left: 16px;
                margin-top: 0;
            }
        }
    }

    &-Step {
        .Field {
            &-CheckboxLabelContent {
                font-weight: 500;
                font-size: 13px;
                letter-spacing: 0.26px;
                color: $color-primary-85;
            }

            [type="checkbox"] {
                + .input-control {
                    border-color: $color-primary-85;
                }

                &:checked + .input-control, &:hover:not(:disabled) + .input-control {
                    --box-color: #{$color-other-8};

                    border-color: $color-other-8;

                    &::after {
                        --checkmark-color: #{$color-neutral-5};
                    }
                }

                &:not(:checked):hover + .input-control {
                    --box-color: #{$color-primary-85};

                    border-color: $color-primary-85;

                    &::after {
                        --checkmark-color: #{$color-neutral-5};
                    }
                }

                &:checked {
                    ~ .Field-CheckboxLabelContent:nth-of-type(2) {
                        color: $color-other-8;
                    }
                }
            }
        }
    }

    &-TermsAndConditions {
        > div {
            margin-left: 28px;
        }

        .Field {
            &-CheckboxLabelContent {
                font-size: 13px;
                letter-spacing: 0.26px;

                p {
                    font-size: 13px;
                }

                a {
                    color: $color-neutral-70;
                }
            }

            [type="checkbox"] {
                + .input-control {
                    border-color: $color-neutral-70;
                }
            }
        }

        .Checkout {
            &-MarkAll {
                margin-left: 0;

                .Field {
                    &-CheckboxLabelContent {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    &-ActionButtonWrapper {
        .Button {
            width: 100%;
            background: $color-primary-85;
            border-color: $color-primary-85;
        }
    }
}

@include mobile {
    .Checkout {
        padding-top: 67px;

        .CheckoutOrderSummary-ButtonWrapper {
            display: block;
        }

        &-Heading {
            margin-top: 20px;
            font-size: 24px;
            margin-bottom: 24px;
            line-height: 32px;
        }

        &-SubHeading {
            font-size: 21px;
        }
    }
}

@include mobileAndTablet {
    .Checkout {
        &-Addresses {
            margin-bottom: 48px;
        }
    }
}

@include tabletAndAbove {
    .Checkout {
        &-Wrapper {
            grid-gap: 3%;
        }
    }
}

@include desktopAndAbove {
    .Checkout {
        &-Wrapper {
            grid-template-columns: 2fr 1fr;
            grid-gap: 2%;
        }

        &-SingleStep {
            display: flex;
            grid-gap: 3%;
        }
    }
}

@include above1200 {
    .Checkout {
        &-Wrapper {
            grid-gap: 7%;
        }

        &-SingleStep {
            grid-gap: 11%;
        }
    }
}

